const SEARCH_INDEX = [
  {
    node: {
      excerpt:
        "A task is a piece of code that is placed in a queue and is picked up by the  event loop  to run at an appropriate time.\nRunning code inside…",
      fields: {
        slug: "/breaking-long-tasks-in-js/",
      },
      frontmatter: {
        date: "2025-03-19T16:40:32.169Z",
        title: "Keeping the UI Responsive: Managing Long Tasks in JavaScript",
        timeToRead: 10,
        tags: ["performance", "javascript", "long-task", "ux"],
        description:
          "Long tasks can block the main thread, causing dropped frames and leading to a slow, unresponsive UI. This article explains how to optimize long tasks by breaking them into smaller ones and prioritizing UI updates over non-user-visible tasks.",
      },
    },
  },
  {
    node: {
      excerpt:
        "React introduced Suspense API to support code splitting of JS modules in combination with React.lazy(), allowing components to be loaded…",
      fields: {
        slug: "/react-suspense-for-data-fetching/",
      },
      frontmatter: {
        date: "March 09, 2025",
        title: "React Suspense for data fetching",
        timeToRead: 8,
        tags: ["react", "performance", "suspense", "data fetching"],
        description:
          "Learn how to you Suspense for data fetching in React that lets you declaratively manage the loading state of your app.",
      },
    },
  },
  {
    node: {
      excerpt:
        "I haven’t been using React state much lately when it comes to building forms of any size. \nThe HTML and CSS have advanced so much that we…",
      fields: {
        slug: "/from-stateful-to-stateless-my-approach-to-building-form/",
      },
      frontmatter: {
        date: "November 10, 2024",
        title:
          "From stateful to stateless: my approach to building forms in React app",
        timeToRead: 8,
        tags: ["react", "html'", "css"],
        description:
          "This article talks about my approach to building accessible forms using HTML and CSS only, instead of relying of lots of stateful React code. With this approach, you can build a fully functional form that delivers a great user experience.",
      },
    },
  },
  {
    node: {
      excerpt:
        "Why Error Boundary React Error boundary  component  captures errors during rendering  and allows you to display fallback UI instead of…",
      fields: {
        slug: "/error-boundary-react-query-and-router-v6/",
      },
      frontmatter: {
        date: "2024-08-11T16:40:32.169Z",
        title: "How to use Error Boundary with React Query and Router v6",
        timeToRead: 4,
        tags: ["react"],
        description:
          "Leverage React Router v6’s errorElement API in conjunction with React Query’s throwOnError option to provide seamless UI fallbacks for errors occurring during both rendering and asynchronous operations",
      },
    },
  },
  {
    node: {
      excerpt:
        "Imagine you're working on the front-end of a web application, integrating a REST API endpoint provided by your back-end colleague. You…",
      fields: {
        slug: "/demystifying-cross-origin-resource-sharing-on-web/",
      },
      frontmatter: {
        date: "October 06, 2023",
        title: "Demystifying Cross-Origin Resource Sharing (CORS) on Web",
        timeToRead: 4,
        tags: ["security", "web"],
        description:
          "CORS is an HTTP-header based mechanism that allows sharing resources from one origin to another. In this article, we will explore the CORS protocol in more detail and see how the browser decides if it needs to make a preflight request or not.",
      },
    },
  },
  {
    node: {
      excerpt:
        "JavaScript has grown up to be a powerful language. Starting from its usage of making things interactive in the browser to running complex…",
      fields: {
        slug: "/do-you-even-need-js-for-your-basic-html-forms/",
      },
      frontmatter: {
        date: "August 27, 2023",
        title: "Do you even need JavaScript for your basic HTML forms?",
        timeToRead: 10,
        tags: ["html", "javascript"],
        description:
          "Using only the semantic HTML, we'll implement a basic login form without writing a single line of JavaScript. We will follow <strong>the Rule of Least Power</strong> and explore why native HTML (the least powerful language) should be used wherever possible to deliver an inclusive web experience.",
      },
    },
  },
  {
    node: {
      excerpt:
        "When users interact with web applications, they expect immediate feedback for their actions. Providing timely and relevant feedback is…",
      fields: {
        slug: "/how-to-create-inclusive-feedback-in-spas/",
      },
      frontmatter: {
        date: "July 30, 2023",
        title: "How to create inclusive feedback in SPAs",
        timeToRead: 5,
        tags: ["react", "accessibility"],
        description:
          "In many Single Page Applications, dynamic feedback is primarily designed for sighted users, inadvertently excluding those who rely on screen readers. This article is here to guide you on harnessing the power of ARIA regions to implement an inclusive and accessible solution.",
      },
    },
  },
  {
    node: {
      excerpt:
        "In  part 1 of how to create an accessible modal component in react , we established a solid foundation for our modal component. It included…",
      fields: {
        slug: "/how-to-create-an-accessible-modal-component-in-react-part-2/",
      },
      frontmatter: {
        date: "July 02, 2023",
        title: "How to create an accessible Modal component in React—Part 2",
        timeToRead: 10,
        tags: ["react", "accessibility"],
        description:
          "We will continue our journey of making our modal more accessible and keyboard friendly. This part will address <strong>keyboard interaction</strong> including <strong>focus trap</strong>, and <strong>scroll lock</strong>.",
      },
    },
  },
  {
    node: {
      excerpt:
        "Modals  are used to grab the user's attention to perform a task. They put your system into a special mode requiring user interaction. As a…",
      fields: {
        slug: "/how-to-create-an-accessible-modal-component-in-react-part-1/",
      },
      frontmatter: {
        date: "April 30, 2022",
        title: "How to create an accessible Modal component in React—Part 1",
        timeToRead: 6,
        tags: ["react", "accessibility"],
        description:
          "Creating an accessible Modal dialog will make it inclusive and accessible to all the users, including keyboard and screen readers. This article will take you through the process of implementing one from scratch by starting off with the foundation, mouse interaction, and adding the required aria attributes.",
      },
    },
  },
  {
    node: {
      excerpt:
        "I thought I knew enough about how  positioning  and  z-index  works in HTML and CSS. But recently, I learned a new concept that completely…",
      fields: {
        slug: "/stacking-rules-for-html-elements/",
      },
      frontmatter: {
        date: "March 21, 2022",
        title: "Understanding stacking rules for the HTML elements",
        timeToRead: 4,
        tags: ["html", "css"],
        description:
          'Understanding the layering order (or "stack order") of the HTML elements is key to understanding how the `z-index` works. This article will look into how the browser layers elements on the page by default, and how we can use `z-index` to rearrange that order.',
      },
    },
  },
  {
    node: {
      excerpt:
        "Tabs component  needs no introduction—they are ubiquitous. They are handy when we want to show a variation of UI content in a limited space…",
      fields: {
        slug: "/how-to-create-an-accessible-tabs-component-in-react/",
      },
      frontmatter: {
        date: "August 22, 2021",
        title: "How to create an accessible Tabs component in React?",
        timeToRead: 10,
        tags: ["react", "accessibility"],
        description:
          "Creating an accessible component is not as hard as you think. It only requires the right attitude and a bit of empathy for your users.",
      },
    },
  },
  {
    node: {
      excerpt:
        "My initial career path was more focused on JavaScript; CSS was not part of my Jira tickets. I remember asking my colleague, who was good at…",
      fields: {
        slug: "/things-you-might-not-know-css/",
      },
      frontmatter: {
        date: "August 09, 2021",
        title: "A few things you might not know about CSS",
        timeToRead: 8,
        tags: ["css", "tips"],
        description:
          "We'll look into a few caveats, gotchas, and some 'Aha' moments in CSS which you might not be aware of. Do check out the CodePen demos, as they speak louder than words.",
      },
    },
  },
  {
    node: {
      excerpt:
        "What is aria-current? As per  W3C definition ,  aria-current  attribute can be used to identify or visually style an element among a set of…",
      fields: {
        slug: "/using-aria-current-for-nav-links/",
      },
      frontmatter: {
        date: "July 26, 2021",
        title: "Using aria-current for active nav link in NextJS app",
        timeToRead: 7,
        tags: ["accessibility", "tips", "nextjs", "react"],
        description:
          "The aria-current attribute can help screen reader users recognise the currently active link among a set of navigation links. We'll learn how easily you can inject this attribute into your link tags inside your NextJS app.",
      },
    },
  },
  {
    node: {
      excerpt:
        "Git branching  allows developers to work in isolation. Once your work is completed, you can merge your branch back into the master. In a few…",
      fields: {
        slug: "/git-merge-conflicts/",
      },
      frontmatter: {
        date: "July 18, 2021",
        title: 'Strategies to resolve git conflicts using "theirs" and "ours"',
        timeToRead: 3,
        tags: ["git", "tips"],
        description:
          "While resolving merge conflicts, you could instruct git to pick all the changes made to one branch over another. In this article, we'll see how you can achieve it in a single-line command.",
      },
    },
  },
  {
    node: {
      excerpt:
        'While conducting an interview session, I asked a candidate about what web accessibility is, the answer was, "Isn\'t it about aria-label?". It…',
      fields: {
        slug: "/thinking-about-accessibility-a-few-tips/",
      },
      frontmatter: {
        date: "June 19, 2021",
        title: "Thinking about accessibility—a few tips to make a difference",
        timeToRead: 9,
        tags: ["accessibility", "react", "html", "css", "tips"],
        description:
          "It's time we stop considering web accessibility as an optional thing. Read a few tips that could help you get up and running with accessibility in mind.",
      },
    },
  },
  {
    node: {
      excerpt:
        "Imagine you've been to a café and order a cup of coffee ☕. Once the bill 🧾 arrives, it isn't only for the coffee you ordered, but all the…",
      fields: {
        slug: "/lazy-loading-react-components-no-rocket-science/",
      },
      frontmatter: {
        date: "May 30, 2021",
        title: "Lazy-loading React components—no rocket science",
        timeToRead: 4,
        tags: ["react", "nextjs", "performance", "dynamic-import"],
        description:
          "Don't make your user pay the cost of additional JavaScript they never get to use. Think inclusive, think accessible.",
      },
    },
  },
  {
    node: {
      excerpt:
        "Taking one step forward Learning is an unending process. The era we live in carries a plethora of resources to grab those learning…",
      fields: {
        slug: "/a-list-of-newsletter-subscriptions-to-level-up-your-coding-skills/",
      },
      frontmatter: {
        date: "May 15, 2021",
        title: "Curated list of Newsletters to level up your coding skills",
        timeToRead: 3,
        tags: ["productivity", "newsletter", "javascript", "html", "css"],
        description:
          "Level up your programming skills by subscribing to this curated list of newsletter subscriptions.",
      },
    },
  },
  {
    node: {
      excerpt:
        "Events are everywhere in web programming — input change, button click, and page scroll are all forms of events. These are the actions that…",
      fields: {
        slug: "/are-you-sure-you-know-how-event-propagates-in-javascript/",
      },
      frontmatter: {
        date: "February 17, 2021",
        title: "Are you sure you know how event propagates in JavaScript?",
        timeToRead: 4,
        tags: ["javascript", "events", "propagation"],
        description:
          "Events are core part of web programming. System notifies us about these occurrences and we can respond them however we desire. Understanding the event propagation is important to create robust UI widgets.",
      },
    },
  },
  {
    node: {
      excerpt:
        "JavaScript language stands on two pillars:  functional programming  and prototypal delegation. The addition of  classes  in JavaScript is…",
      fields: {
        slug: "/an-easy-explanation-to-prototypal-delegation-in-javascript/",
      },
      frontmatter: {
        date: "February 15, 2021",
        title: "An Easy Explanation to Prototypal Delegation in JavaScript",
        timeToRead: 4,
        tags: [
          "javascript",
          "prototypal",
          "delegation",
          "function",
          "inheritance",
        ],
        description:
          "JavaScript doesn't have classical inheritance, but Prototypal delegation. This allows a very powerful paradigm of sharing methods and properties in JavaScript.",
      },
    },
  },
  {
    node: {
      excerpt:
        "TLDR Running  git reset --soft <commit-hash>  will move the branch to this old commit. And now when you run  git status , you will see all…",
      fields: {
        slug: "/how-to-smartly-cleanup-your-commit-history-using-git/",
      },
      frontmatter: {
        date: "August 17, 2020",
        title: "How to smartly cleanup your commit history using git reset?",
        timeToRead: 4,
        tags: ["git", "tips", "productivity", "version-control"],
        description:
          "Strive to keep your commit history clean, and your future colleagues will thank you for this. Let's see how can you do it using git reset.",
      },
    },
  },
  {
    node: {
      excerpt:
        "Git  has a plethora of commands at your disposal. But we often end up using only a handful of those in our daily git workflow. The  git add…",
      fields: {
        slug: "/git-interactive-enhance-your-work-productiviy-by-10X/",
      },
      frontmatter: {
        date: "August 01, 2020",
        title: "Git Interactive—enhance your work productivity by 10X",
        timeToRead: 3,
        tags: ["git", "version-control", "productivity", "tips"],
        description:
          "It's time to supercharge your git tooling. We will go beyond your normal git add and git checkout command, and showcase their interactive counterparts.",
      },
    },
  },
  {
    node: {
      excerpt:
        "I am sure you might have seen the following line of code either while reading someone’s code or in a library. And now you are wondering what…",
      fields: {
        slug: "/what's-the-deal-with-Object.prototype.hasOwnProperty.call()/",
      },
      frontmatter: {
        date: "April 26, 2020",
        title: "What's the deal with Object.prototype.hasOwnProperty.call()?",
        timeToRead: 3,
        tags: ["javascript", "prototypal", "inheritance"],
        description:
          "We will break the components into pieces and demystify their existance. You will learn about prototye delegation, borrowing function techinque, and why one would use hasOwnProperty on Object's prototype and not on instance itself.",
      },
    },
  },
  {
    node: {
      excerpt:
        "For a long time React developers have been inventing and sharing different patterns of reusing code logic —  Higher order component  and…",
      fields: {
        slug: "/why-effects-shouldn't-lie-about-their-dependencies/",
      },
      frontmatter: {
        date: "July 01, 2019",
        title: "Why Effects shouldn't lie about their dependencies",
        timeToRead: 9,
        tags: ["react", "hooks"],
        description:
          "Effects play a crucial part in your application. Understanding why its important to pass correct dependencies in your `useEffect` hook can help you avoid bugs.",
      },
    },
  },
  {
    node: {
      excerpt:
        "Anything that cut some of your arduous task time is good for your productivity. Debugging is one of those strenuous tasks. Developers strive…",
      fields: {
        slug: "/hidden-gems-of-debugging/",
      },
      frontmatter: {
        date: "May 25, 2019",
        title: "Hidden gems of debugging",
        timeToRead: 8,
        tags: ["debugging", "tips", "productivity", "dev-tools"],
        description:
          "We will explore some of the coolest tricks and features that can make 'debugging' your cup of tea ☕.",
      },
    },
  },
  {
    node: {
      excerpt:
        "Closures  can be a daunting concept when you are new to JavaScript world. Scouring the internet will give you tons of definition about what…",
      fields: {
        slug: "/lexical-envrionment-the hidden-part-to-understand-closures/",
      },
      frontmatter: {
        date: "May 12, 2019",
        title: "Lexical Environment-the hidden part to understand Closures",
        timeToRead: 6,
        tags: ["closure", "lexical", "scope", "javascript"],
        description:
          "Functions in ECMAScript captures their parent scope bindings at creation time",
      },
    },
  },
  {
    node: {
      excerpt:
        "JavaScript  is an interesting language and we all love it because of its nature. Browsers are the home for JavaScript and both work together…",
      fields: {
        slug: "/so-you-think-you-know-JavaScript/",
      },
      frontmatter: {
        date: "March 21, 2019",
        title: "So you think you know JavaScript?",
        timeToRead: 6,
        tags: ["javascript", "closure", "scope", "event loop", "promises"],
        description:
          "We demystify the mechanism of JavaScript engine, closures, scope, and event loop.",
      },
    },
  },
  {
    node: {
      excerpt:
        "We all know that in order to pass a function as props to the child component we have to do one of the following: Bind it inside the…",
      fields: {
        slug: "/why-do-we-need-to-bind-methods-inside-out-component's-constructor/",
      },
      frontmatter: {
        date: "December 12, 2018",
        title:
          "Why do we need to bind methods inside our component’s constructor?",
        timeToRead: 3,
        tags: ["react", "component", "javascript", "function"],
        description: "This is not React but JavaScript.",
      },
    },
  },
  {
    node: {
      excerpt:
        "The year 2018 brought a lot of new features into the React Ecosystem. The addition of these features is helping developers to focus more on…",
      fields: {
        slug: "/how-to-write-cleaner-code-with-hooks/",
      },
      frontmatter: {
        date: "December 07, 2018",
        title: "How to write 90% cleaner code with Hooks 🎣",
        timeToRead: 4,
        tags: ["react", "hooks"],
        description:
          "Hook are the new programming model in React. Are you up and ready to embrace it?",
      },
    },
  },
  {
    node: {
      excerpt:
        "Extending your class components from  PureComponents  gives you the benefit of avoiding wasteful re-renders. It does a shallow comparison of…",
      fields: {
        slug: "/purecomponent-caveats/",
      },
      frontmatter: {
        date: "October 28, 2018",
        title: "PureComponent Caveats",
        timeToRead: 4,
        tags: ["react", "component", "performance", "tips"],
        description: "Avoid wasteful re-renders.",
      },
    },
  },
  {
    node: {
      excerpt:
        "I have been using React for two years now. I must say the journey so far was exciting and has taught me many things. My first React learning…",
      fields: {
        slug: "/thinking-in-react/",
      },
      frontmatter: {
        date: "April 08, 2018",
        title: "Thinking in React—few tips",
        timeToRead: 4,
        tags: ["react", "tips"],
        description: "Some tips to help you get started with React.",
      },
    },
  },
];

export default SEARCH_INDEX;
